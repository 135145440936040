/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              Sportschool Tromp is vooral bekend om de Budosporten. Wij doceren al bijna 50 jaar Judo, Karate en Aikibudo binnen onze school. De Budosporten staan vooral bekend vanwege de traditionele grondslag.
            </Grid>
            <Grid item xs={12} md={4}>
              De afgelopen periode heeft er een verbreding plaats gevonden van onze activiteiten, je kunt bij ons ook terecht voor Grappling en Personal Training.
            </Grid>
            <Grid item xs={12} md={4}>
              Daarnaast bieden wij diverse cursussen en clinics aan voor scholen, particulieren en bedrijven. Verder zijn er gedurende het jaar meerdere onderlinge wedstrijden en speciale trainingen.
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
