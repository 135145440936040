// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Sportschool Tromp",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/sportschooltrompalblasserwaard",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/SportschoolTrompMolenlanden",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/Budo-Ryu-Ikigai-109370314193494",
    },
  ],
  menus: [
    {
      name: "Algemeen",
      items: [
        { name: "Homepagina", route: "/"},
        { name: "Lid Worden", route: "/lid_worden" },
        { name: "Contact", route: "/contact" },
      ]
    },
    {
      name: "Sporten",
      items: [
        { name: "Algemeen", route: "/sporten" },
        { name: "Judo", route: "/judo" },
        { name: "Karate", route: "/karate" },
        { name: "Aikibudo", route: "/aikibudo" },
        { name: "BJJ / Grapling", route: "/bjj" },
        { name: "Daito-ryu Aiki JuJutsu", route: "/daito" },
      ],
    },
    {
      name: "Overige",
      items: [
        { name: "Tijden & Locaties", route: "/tijden" },
        { name: "Prijzen", route: "/lid_worden" },
        { name: "Actueel", route: "/actueel" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} {" "}
      <MKTypography
        component="a"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        RheWa.Design
      </MKTypography>
      .
    </MKTypography>
  ),
};
