/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

function Prices() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                Er zijn verschillende contributies mogelijk. Wil je direct inschrijven dan kan dit via het onderstaand inschrijfformulier waar je tevens ook de algemene voorwaarden kunt vinden. Voor een gratis proefles verwijzen wij u naar de Home pagina.
            </Grid>
            <Grid item xs={12} xl={6}>
                <MKTypography
                  variant="h1"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Contributies
                </MKTypography>
                Prijzen zijn onder voorbehoud van wijzigingen.
            </Grid>
            
            <Grid item display={{ xs: "none", xl: "block" }} xl={6}>
              <MKTypography
                  variant="h1"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Knipkaart
                </MKTypography>
                Alle kaarten dienen voor de les afgegeven te worden aan de leiding. Na de les worden de kaarten weer teruggegeven.
            </Grid>
            
            <Grid item xs={12} xl={6}>
                <MKTypography
                  variant="h3"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                    Proefmaand (Eenmalig)
                </MKTypography>
                <Grid container mt={2}>
                    <Grid item xs={8} xl={4}>Jeugd 6 - 11 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 20,00</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={8} xl={4}>Jeugd 12 - 15 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 22,00</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={8} xl={4}>Vanaf 16 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 26,50</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                </Grid>
            </Grid>

            <Grid item display={{ xs: "none", xl: "block" }} xl={6}>
                <MKTypography
                    variant="h3"
                    color="Dark"
                    sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                        },
                    })}
                >
                    3 Maanden, 12 lessen
                </MKTypography>
                <i>Geen inschrijfgeld of opzegtermijn.</i>
                <Grid container mt={2}>
                    <Grid item xs={4}>Jeugd 6 - 11 jaar</Grid>
                    <Grid item xs={3}>€ 78,00</Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={4}>Jeugd 12 - 15 jaar</Grid>
                    <Grid item xs={3}>€ 86,00</Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={4}>Vanaf 16 jaar</Grid>
                    <Grid item xs={3}>€ 103,50</Grid>
                    <Grid item xs={5}></Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} xl={6}>                
                <MKTypography
                  variant="h3"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                    Contributie per maand
                </MKTypography>
                <i>Het inschrijfgeld is €10,-. We houden 1 maand opzegtermijn aan.</i>

                <Grid container mt={2}>
                    <Grid item xs={4}><b>Leeftijd</b></Grid>
                    <Grid item xs={4} xl={3}><b>1x per week</b></Grid>
                    <Grid item xs={4} xl={3}><b>Onbeperkt</b></Grid>
                    <Grid item xs={2} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={4} xl={4}>6 - 11</Grid>
                    <Grid item xs={4} xl={3}>€ 20,00</Grid>
                    <Grid item xs={4} xl={3}>€ 29,00</Grid>
                    <Grid item xs={2} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={4} xl={4}>12 - 15</Grid>
                    <Grid item xs={4} xl={3}>€ 22,00</Grid>
                    <Grid item xs={4} xl={3}>€ 32,50</Grid>
                    <Grid item xs={2} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={4} xl={4}>16+</Grid>
                    <Grid item xs={4} xl={3}>€ 26,50</Grid>
                    <Grid item xs={4} xl={3}>€ 39,50</Grid>
                    <Grid item xs={2} display={{ xs: "none", xl: "block" }}></Grid>
                </Grid>
            </Grid>


            
            <Grid item display={{ xs: "block", xl: "none" }} xs={12}>
              <MKTypography
                  variant="h1"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Knipkaart
                </MKTypography>
                Alle kaarten dienen voor de les afgegeven te worden aan de leiding. Na de les worden de kaarten weer teruggegeven.
            </Grid>
            <Grid item display={{ xs: "block", xl: "none" }} xs={12}>
                <MKTypography
                    variant="h3"
                    color="Dark"
                    sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                        },
                    })}
                >
                    3 Maanden, 12 lessen
                </MKTypography>
                <i>Geen inschrijfgeld of opzegtermijn.</i>
                <Grid container mt={2}>
                    <Grid item xs={8} xl={4}>Jeugd 6 - 11 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 78,00</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={8} xl={4}>Jeugd 12 - 15 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 86,00</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={8} xl={4}>Vanaf 16 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 103,50</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
                <MKTypography
                    variant="h3"
                    color="Dark"
                    sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                        },
                    })}
                >
                    26 Lessen
                </MKTypography>
                <i>Geen inschrijfgeld of opzegtermijn. Deze kaart is 1 jaar geldig en is speciaal voor wie niet regelmatig de lessen kan volgen.</i>
                <Grid container mt={2}>
                    <Grid item xs={8} xl={4}>Jeugd 6 - 11 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 160,00</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={8} xl={4}>Jeugd 12 - 15 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 174,00</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                    <Grid item xs={8} xl={4}>Vanaf 16 jaar</Grid>
                    <Grid item xs={4} xl={3}>€ 211,50</Grid>
                    <Grid item xs={5} display={{ xs: "none", xl: "block" }}></Grid>
                </Grid>
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Prices;
