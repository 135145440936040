/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
                Sportschool Tromp is al meer dan vijftig jaar de plek waar Budolessen aangeboden worden aan kinderen, jongeren, volwassenen en senioren uit de omgeving Sliedrecht, Nieuw-Lekkerland en Streefkerk. 
                Sportschool Tromp is één van de eerste sportscholen in Nederland die Judo, Karate en Aikibudo lessen aanbied. 
                Teun Tromp, oprichter in 1966, heeft de sportschool in 2014 overgedragen aan zoon Theo Tromp.
            </Grid>
            <Grid item xs={12} lg={4}>
                Het afgelopen jaar heeft er een verbreding in de activiteiten plaatsgevonden en bieden wij ook Grappling, Personal Training en (Sport)massage aan. 
                Tevens geven wij ook diverse cursussen en clinics.
            </Grid>
            <Grid item xs={12} lg={4}>
                Sport en het werken met mensen is onze passie. 
                Via onze lessen dragen wij de waarden van sport over. 
                Waarden zoals omgaan met winst en verlies, doorzettingsvermogen en succes ervaring. 
                Via sport willen we kinderen, jongeren, volwassenen en senioren ook weerbaarder maken en weer fit krijgen.
                <br /><br />Wij werken met rijkserkende leerkrachten en zijn aangesloten bij de JBN, KBN, WIKF, NSF, NVJJL en BMS.
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
