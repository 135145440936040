/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

import judo2 from "assets/images/Judo2.jpg"

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              Het doel van Budo Ryu Ikigai is zoals de naam aangeeft, het volgen van je passie als het gaat om Japanse krijgskunsten. Hoe haal je energie uit je sport? Wat maakt het dat je er mee door wilt blijven gaan? Deze drijfveren zijn voor iedereen anders en goed in te passen binnen Budo Ryu Ikigai.
              Budo gaat verder dan alleen zelfverdediging. Het zorgt ook voor ontwikkeling van lichaam, geest en bewustzijn. Door het trainen krijg je een betere conditie, flexibiliteit en beheersing van je lichaam maar ook van je zelf. Training gebeurt met respect voor iedereen in de les. Budo heeft ook duidelijk een spiritueel karakter. Dit is terug te vinden in het perfectioneren van de technieken, waardoor ze moeiteloos uitgevoerd kunnen worden en het vergroten van je innerlijke rust.
              Sinds 2018 zijn er wekelijkse lessen Daito-ryu Aikijujutsu in het Budocentrum Sliedrecht. Daito-ryu Aikijujutsu is een traditionele Japans vorm van Budo zoals vele honderden jaren door de Takeda familie is beoefend. De technieken vinden hun oorsprong in de klasse van de Samourai en is ontwikkeld als een methode van zelfverdediging tegen aanvallen waarbij de nadruk ligt in het neutraliseren van het geweld en niet het zelf veroorzaken. Dit is een van de redenen waarom er alleen vaste vormen (kata) en variaties daarop getraind worden en er geen wedstrijden zijn.
              Daito-ryu bevat Aikijujutsu en Jujutsu elementen met de nadruk op het toepassen van Aiki. De manieren van balansverstoring zijn de zichtbaarste vorm van Aiki. Het is de oorsprong van verschillende moderne vormen van Budo waarvan Aikido de belangrijkste is. Ook het Judo is beïnvloed door het Daito-ryu. Door het toepassen van Aiki in de technieken en dit te vervolmaken zal uiteindelijk ook een kleiner iemand een groot en sterk persoon kunnen neutraliseren.
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
