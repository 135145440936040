/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages

// Sections
import IndexPage from "pages/IndexPage";
import SportsPage from "pages/Sports/SportsPage";
import JudoPage from "pages/Sports/JudoPage";
import AikibudoPage from "pages/Sports/AikibudoPage";
import KaratePage from "pages/Sports/KaratePage";
import BJJPage from "pages/Sports/BJJPage";
import DaitoPage from "pages/Sports/DaitoPage";
import TimesPage from "pages/Other/TimesPage"
import LidwordenPage from "pages/LidwordenPage";
import ContactPage from "pages/ContactPage";
import ActualPage from "pages/Other/ActualPage";

const routes = [
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/",
    component: <IndexPage />,
  },
  {
    name: "Sporten",
    icon: <Icon>sports_martial_arts</Icon>,
    collapse: [
      {
        name: "Algemeen",
        route: "/sporten",
        component: <SportsPage />,
      },
      {
        name: "Judo",
        route: "/judo",
        component: <JudoPage />,
      },
      {
        name: "Karate",
        route: "/karate",
        component: <KaratePage />,
      },
      {
        name: "Aikibudo",
        route: "/aikibudo",
        component: <AikibudoPage />,
      },
      {
        name: "BJJ / Grappling",
        route: "/bjj",
        component: <BJJPage />,
      },
      {
        name: "Daito-ryu Aiki Jujutsu",
        route: "/daito",
        component: <DaitoPage />,
      },
    ],
  },
  {
    name: "Lid Worden",
    icon: <Icon>edit</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    route: "/lid_worden",
    component: <LidwordenPage />,
  },
  {
    name: "Overige",
    icon: <Icon>info</Icon>,
    collapse: [
      {
        name: "Prijzen",
        route: "/lid_worden",
        component: <LidwordenPage />
      },
      {
        name: "Actueel",
        route: "/actueel",
        component: <ActualPage />
      },
      {
        name: "Contact",
        route: "/contact",
        component: <ContactPage />,
      },
    ],
  },
  {
    name: "Tijden en Locaties",
    icon: <Icon>access_time</Icon>,
    route: "/tijden",
    component: <TimesPage />
  },
];

export default routes;
