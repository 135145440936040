/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import judo from "assets/images/Judo_Small.jpg";
import karate from "assets/images/Karate_Small.jpg";
import aikibudo from "assets/images/Aikibudo_Small.jpg";
import bjj from "assets/images/BJJ_Small.jpg";
import daito from "assets/images/Daito_Small.jpg";


function SpecialeDagen() {
  return (
    <MKBox
      bgColor="white"
      component="section"
      color="dark"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h2" color="dark">
              Speciale lessen 2024 - 2025
            </MKTypography>
            <MKTypography variant="body2" color="dark" opacity={0.8}>
              Alle data zijn onder voorbehoud. Voor zekerheid vraag na tijden de les.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h4" color="dark" opacity={0.8}>Proefexamens/Slippentest (Karate)</MKTypography>
            <b><i>Week 48 & 49:</i></b><br /> 25 November 2024 t/m 7 December 2024 <br /><br />
            <b><i>Week 25 & 26:</i></b><br /> 16 Juni 2025 t/m 28 Juni 2025 
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h4" color="dark" opacity={0.8}>Examens (Karate)</MKTypography>
            <b><i>Week 4:</i></b><br /> 25 Januari 2025 <br /><br />
            <b><i>Week 28:</i></b><br /> 12 Juli 2025
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h4" color="dark" opacity={0.8}></MKTypography>
            
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SpecialeDagen;
