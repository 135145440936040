/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

import judo2 from "assets/images/Judo2.jpg"

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              Judo is een verdedigingssport en een zelfbeheersingssport. Je leert er op de goede manier vallen en je te concentreren. Je leert oefeningen zoals valoefeningen, voorwaarts rollen, maar ook worpen, houdgrepen, wurggrepen en klemmen. Ook zul je in de lessen Randori’s maken wat inhoudt dat je tegen elkaar wat gaat vechten of je beoefent de worpen wat sneller na elkaar de zogeheten Yaku Soko Geiko.
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKTypography
                  variant="h2"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Voor de jeugd
                </MKTypography>
                Vanaf 5 jaar kunnen jongens en meisjes bij ons aan judo deelnemen. Judo helpt kinderen in hun ontwikkeling op sociaal en motorisch gebied.<br /> 
                De lessen zijn laagdrempelig en de stof, werp- en technieken en verbindingen, worden stap voor stap aangeboden. Ingespeeld op het stoeigedrag en de natuurlijke bewegingsdrang van kinderen.<br />
                Enkele aspecten die de kinderen leren zijn leren vallen en doorgaan, omgaan met elkaar, zelfvertrouwen wordt gestimuleerd, weerbaarheid wordt vergroot, discipline, respect en vooral heel veel plezier.<br />
                Tevens zullen er diverse onderlinge wedstrijdjes georganiseerd worden en 1 keer per jaar een groot judo tournooi. Ook kunnen de kinderen twee keer per jaar examen doen voor hun volgende slip of band.<br />
            </Grid>
            <Grid item lg={6}>
              <img src={judo2} width="100%" />
            </Grid>
            <Grid item xs={12}>
              <MKTypography
                  variant="h2"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Geschiedenis
                </MKTypography>
                  Deze sport is ongeveer 120 jaar geleden uitgevonden door de japanner Jigoro Kano. 
                  Hij wilde een wedstrijdsport ontwikkelen die ongevaarlijk was en ook veel plezier zou schenken. 
                  Hij haalde de gevaarlijke technieken zoals de been- en nekklemmen en ook de schoppen en het slaan uit het jiu-jitsu en noemde het judo. 
                  Het judo kwam rond 1945 naar Europa (toen jullie opa en oma klein waren). 
                  Het werd in Nederland pas populair toen Anton Geesink in 1961 wereldkampioen werd. 
                  Misschien hebben jullie ook wel gehoord van Mark Huizinga die op de Olympische Spelen in 2000 een gouden medaille heeft gewonnen.
            </Grid>
            <Grid item xs={12}>
              <MKTypography
                  variant="h2"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Judoband
                </MKTypography>
                  De band die een judoka draagt dient ervoor om de judogi (judojas) dicht te houden en de kleur geeft aan hoe ver je al bent met judo. 
                  Je begint met de witte band, voor elk bandexamen moet je een aantal grepen en worpen kennen. 
                  Hoe hoger je komt hoe meer worpen en grepen je moet kunnen en kennen. De grepen worden steeds moeilijker. 
                  Judoka's tot 12 jaar kunnen ook slippen halen, dit zijn tussenstapjes tot een bandexamen. 
                  Als je nog maar de helft van de grepen voor de gele band weet, krijg je de gele slip. 
                  Vanaf 4 jaar kan je al op judo en je ziet ook wel ouderen boven de 60 jaar die nog aan judo doen. 
                  Voor sommige kinderen vindt de dokter het goed dat je op judo gaat want je leert lekker met elkaar stoeien zonder elkaar pijn te doen. 
                  Daarnaast leer je goed bewegen en goed luisteren. Ook krijg je er zelfvertrouwen van en leer je wat winnen en verliezen is.
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
