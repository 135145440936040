/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

import judo2 from "assets/images/Judo2.jpg"

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={5}>
            <Grid item xs={12}>
              Aikibudo of aikido is een perfecte vorm van zelfverdediging maar ook een mentale training. De technieken stammen voor een groot deel uit de beroemde Japanse Daito school. Oorspronkelijk werd deze krijgskunst Daito ryu jitsu en later Aiki jitsu genoemd. Het was de beroemde leraar Morihei Uyshiba die niet alleen de technieken verder perfectioneerde maar ook de naam wijzigde met de toevoeging ‘do’. De grote stuwkracht van het Aikibudo in Europa is de fransman Alain Floquet, 9e dan Aikibudo.
              Aikibudo is geen wedstrijdsport maar een verdedigingskunst die opvalt door zijn cirkelvormige bewegingen. Gevorderde Aikibudoka dragen een soort broekrok ‘hakama’ genaamd. Een traditioneel Japans kledingstuk dat de sierlijke bewegingen van deze kunst accentueert. De Aikibudo technieken bestaan uit controle technieken, worpen, slagen en stoten op de vitale lichaamspunten. Ook leer je omgaan met klassieke wapens zoals zwaard en stok. Op een moeiteloze wijze wordt en aanval onschadelijk gemaakt. 
            </Grid>
            <Grid item xs={12}>
              Aikido kent een oude oosterse traditie. 
              Aikido is ontwikkeld door Morehei Ueshiba (1883-1969). 
              Deze Japanse leraar heeft veel andere vechtkunsten bestudeerd, voordat hij de principes ontdekte waarop hij het aikido baseerde. 
              De technieken (controle- en werptechnieken) zijn beter te begrijpen vanuit het gebruik van wapens. 
              Aikido kent daarom ook het gebruik van tanto (houten mes), jo (houten stok) en boken (houten zwaard). 
              
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
