/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import OpenDagPooster from "assets/images/Opendag.png";
import MKTypography from "components/MKTypography";

function OpenDag() {

  return (
    <MKBox 
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      color="white"
    >
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <img src={OpenDagPooster} width="100%" />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid Container width="100%">
              <Grid item xs={12} textAlign="center" mb={5}>
                <MKTypography variant="h1" color="white">
                  Open Dag
                </MKTypography>
                <MKTypography variant="h4" color="white">
                  Een middag met <b><i>Demo's</i></b> en <b><i>Mee Trainen</i></b> op de open mat.
                </MKTypography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Grid container width="100%" spacing={3}>
                  <Grid item xs={6} textAlign="right"><MKTypography variant="h3" color="white">13:00 - 14:00</MKTypography></Grid>
                  <Grid item xs={6} textAlign="left"><MKTypography variant="h3" color="white">Mee trainen</MKTypography></Grid>
                  
                  <Grid item xs={3} lg={4} mb={-3}>&nbsp;</Grid>
                  <Grid item xs={4.5} lg={4} mb={-2}><i>Grote Zaal</i></Grid>
                  <Grid item xs={4.5} lg={4} mb={-2}><i>Kleine Zaal</i></Grid>

                  <Grid item sx={{ display: { xs: 'none', lg: 'block'}}} xs={1} mb={-3}>&nbsp;</Grid>
                  <Grid item xs={3} lg={3} mb={-3}><i>13:00 - 13:20</i></Grid>
                  <Grid item xs={4.5} lg={4} mb={-3}>
                    BJJ &amp;<br />
                    Aikibudo
                  </Grid>
                  <Grid item xs={4.5} lg={4} mb={-3}>
                    Kyokushin Karate &amp;<br />
                    Wing Chun
                  </Grid>

                  <Grid item sx={{ display: { xs: 'none', lg: 'block'}}}  xs={1}>&nbsp;</Grid>
                  <Grid item xs={3}><i>13:30 - 13:50</i></Grid>
                  <Grid item xs={4.5} lg={4}>
                    Judo &amp;<br />
                    Daito Ryu Aikijujutsu
                  </Grid>
                  <Grid item xs={4.5} lg={4}>
                    Wado-ryu Karate &amp;<br />
                    Kickboxen
                  </Grid>
                  
                  
                  <Grid item sx={{ display: { xs: 'none', lg: 'block'}}}  xs={1}>&nbsp;</Grid>
                  <Grid item xs={3} mb={-3}>13:00 - 14:50</Grid>
                  <Grid item xs={9} lg={8} mb={-2}><i>Personal training (Cynergy)<br />Yoga (Happy on the Move)</i></Grid>


                  <Grid item xs={6} textAlign="right" mt={4}><MKTypography variant="h3" color="white">14:00 - 15:00</MKTypography></Grid>
                  <Grid item xs={6} textAlign="left" mt={4}><MKTypography variant="h3" color="white">Demo's</MKTypography></Grid>

                  <Grid item xs={6} textAlign="right" mb={-3}>14:00 - 14:05</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Judo</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:05 - 14:10</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Kickboxen</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:10 - 14:15</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>BJJ</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:15 - 14:20</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Wado-ryu Karate</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:20 - 14:25</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Daito Ryu Aikijujutsu</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:25 - 14:30</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Wing-Chun</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:30 - 14:35</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Aikibudo</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:35 - 14:40</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Kyokushin Karate</Grid>
                  <Grid item xs={6} textAlign="right" mb={-3}>14:40 - 14:45</Grid>
                  <Grid item xs={6} textAlign="left" mb={-3}>Judo</Grid>


                  <Grid item xs={6} textAlign="right" mt={4}><MKTypography variant="h3" color="white">15:00 - 16:00</MKTypography></Grid>
                  <Grid item xs={6} textAlign="left" mt={4}><MKTypography variant="h3" color="white">Mee trainen</MKTypography></Grid>
                  
                  
                  <Grid item xs={3} lg={4} mb={-3}>&nbsp;</Grid>
                  <Grid item xs={4.5} lg={4} mb={-3}><i>Grote Zaal</i></Grid>
                  <Grid item xs={4.5} lg={4} mb={-3}><i>Kleine Zaal</i></Grid>

                  <Grid item sx={{ display: { xs: 'none', lg: 'block'}}} xs={1} mb={-3}>&nbsp;</Grid>
                  <Grid item xs={3} mb={-3}><i>15:00 - 15:20</i></Grid>
                  <Grid item xs={4.5} lg={4} mb={-3}>
                    Aikibudo &amp;<br />
                    Judo
                  </Grid>
                  <Grid item xs={4.5} lg={4} mb={-3}>
                    Kyokushin Karate &amp;<br />
                    Kickboxen
                  </Grid>

                  <Grid item sx={{ display: { xs: 'none', lg: 'block'}}} xs={1}>&nbsp;</Grid>
                  <Grid item xs={3}><i>15:30 - 15:50</i></Grid>
                  <Grid item xs={4.5} lg={4}>
                    Daito Ryu Aikijujutsu &amp;<br />
                    BJJ
                  </Grid>
                  <Grid item xs={4.5} lg={4}>
                    Wado-ryu Karate &amp;<br />
                    Wing Chun
                  </Grid>
                  
                  <Grid item sx={{ display: { xs: 'none', lg: 'block'}}}  xs={1}>&nbsp;</Grid>
                  <Grid item xs={3} mb={-3}>13:00 - 14:50</Grid>
                  <Grid item xs={9} lg={8} mb={-2}><i>Personal training (Cynergy)<br />Yoga (Happy on the Move)</i></Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default OpenDag;
