/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/FullLogo.png"
import bgImage from "assets/images/FrontPage.jpg";
import FB from "assets/images/icons/FB.png"
import WA from "assets/images/icons/WA.png"
import Phone from "assets/images/icons/Phone.png"
import Mail from "assets/images/icons/Mail.png"
import Web from "assets/images/icons/Web.png"
import { findByDisplayValue } from "@testing-library/react";



function ContactPage() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <img alt="" src={fullLogo} width="100%" /> 
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          background: 'linear-gradient(to bottom,  #0092d2 0%,#0092d2 45%,#0c5ca3 100%)',
        }}
      >
        
        <MKTypography variant="h2" color="white" mb={1}>
          Molenlanden
        </MKTypography>

        <a href="https://www.facebook.com/SportschoolTrompMolenlanden">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={FB} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          Molenlanden
                        </Grid>
                      </Grid>
                  </Card>
              </Grid>
            </Grid>
          </a>

          <a href="https://www.sportschooltromp.nl" target="_blank">
            <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={Web} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          Website
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>

        <a href="tel:+31630314991">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={Phone} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          (+31) 06 - 30 31 49 91
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>

        <a href="whatsapp://send?phone=31630314991">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={WA} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          (+31) 06 - 30 31 49 91
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>

        <a href="mailto:molenlanden@sportschooltromp.nl">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={Mail} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          molenlanden
                          @sportschooltromp.nl
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>

        <MKTypography variant="h2" color="white" mb={1}>
          Algemeen
        </MKTypography>

        <a href="https://www.facebook.com/sportschooltrompalblasserwaard" target="_blank">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 4,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={FB} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          Sportschool Tromp
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>
        
        <a href="tel:+31643116885">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={Phone} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          (+31) 06 - 43 11 68 85
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>
        
        <a href="whatsapp://send?phone=31643116885">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={WA} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          (+31) 06 - 43 11 68 85
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>

        <a href="mailto:info@sportschooltromp.nl">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={Mail} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          info<br />
                          @sportschooltromp.nl
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>
        
        
        <MKTypography variant="h2" color="white" mb={1}>
          Budocentrum
        </MKTypography>

        <a href="https://www.facebook.com/profile.php?id=100056867635219" target="_blank">
          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 4,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={FB} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          Budocentrum Sliedrecht
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>
        
        <a href="https://www.budocentrum-sliedrecht.nl" target="_blank">
            <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            >
              <Grid item>
                  <Card 
                      sx={{
                      p: 1,
                      mt: 2,
                      mb: 2,
                      width: '100%',
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      background: '#FFFFFF',
                      }}
                  >
                      <Grid container width={250}>
                        <Grid item xs={12}>
                          <img src={Web} width={75}  />
                        </Grid>
                        <Grid item xs={12}>
                          Website<br />
                          Budocentrum Sliedrecht
                        </Grid>
                      </Grid>
                  </Card>
            </Grid>
          </Grid>
        </a>

      </Card>
    </>
  );
}

export default ContactPage;
