/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* EXAMPLES
<Alert variant="filled" severity="success">
  This is a filled success Alert.
</Alert>
<Alert variant="filled" severity="info">
  This is a filled info Alert.
</Alert>
<Alert variant="filled" severity="warning">
  This is a filled warning Alert.
</Alert>
*/


import Alert from "@mui/material/Alert"
import MKAlert from "components/MKAlert";
import MKTypography from "components/MKTypography";

function SiteAlerts() {
  return (
    <>
      {/* <Alert severity="info">
        Momenteel is het vakantie. Lessen starten weer op Maandag 26-08. <i>(Vrij trainen kan natuurlijk zoals altijd in de groepsapp besproken worden)</i>
      </Alert> <br /> */}
      <MKAlert variant="filled" color="warning" dismissible>
        <p>
        Volgens de huidige planning zullen alle lessen van het budocentrum vanaf 1 Oktober verhuizen. De nieuwe locatie is: <a href="https://www.google.com/maps/place/Professor+van+Musschenbroekstraat+4,+3362+SW+Sliedrecht/@51.8206002,4.7790208,17z/data=!3m1!4b1!4m6!3m5!1s0x47c6820cee10419d:0xa2e5b4e5ffef0d14!8m2!3d51.8206002!4d4.7815957!16s%2Fg%2F11h044qnkr?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank"><MKTypography variant="body1" color="dark" opacity={0.8}>Professor van Musschenbroekstraat 4</MKTypography></a>
        Voor deze locatie zullen er wat verandering in het rooster nodig zijn. Deze zullen wij zo snel mogelijk op de website en in de lessen delen.
        </p>
      </MKAlert>
    </>
  );
}

export default SiteAlerts;
