/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import axios from 'axios';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
//import bgImage from "assets/images/examples/blog2.jpg";
import * as React from 'react';
import Alert from '@mui/material/Alert';
import bgImage from "assets/images/Contact.jpg";
import { useCallback, useMemo, useEffect, useState, useRef } from "react";

import "assets/FormStyle.css"
import ReCAPTCHA from "react-google-recaptcha";

function Aanmelden() {
  
  return (
    <MKBox component="section" py={{ xs: 1, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Informatie
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Wil je graag meer informatie? Dan kan je ook contact met ons opnemen.
                    </MKTypography>
                    
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={2} mb={1}>
                        Algemeen:
                    </MKTypography>
                    <>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-user" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                Theo Tromp
                            </MKTypography>
                        </MKBox>
                        <MKBox display="flex" p={0.5}>
                        <MKTypography variant="button" color="white">
                            <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                            component="span"
                            variant="button"
                            color="white"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                        >
                            (+31) 06 - 43 11 68 85
                        </MKTypography>
                        </MKBox>
                        <MKBox display="flex" color="white" p={0.5}>
                        <MKTypography variant="button" color="white">
                            <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                            component="span"
                            variant="button"
                            color="white"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                        >
                            info@sportschooltromp.nl
                        </MKTypography>
                        </MKBox>
                        <MKBox display="flex" color="white" p={0.5}>
                        <MKTypography variant="button" color="white">
                            <i className="fas fa-map-marker-alt" />
                        </MKTypography>
                        <MKTypography
                            component="span"
                            variant="button"
                            color="white"
                            opacity={0.8}
                            ml={2}
                            fontWeight="regular"
                        >
                            Maaslaan 6, 3363CJ, Sliedrecht
                        </MKTypography>
                        </MKBox>
                        <MKBox m={0.5}>
                        <MKButton variant="text" color="white" size="large" iconOnly href="https://www.facebook.com/sportschooltrompalblasserwaard">
                            <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        </MKBox>
                    </>

                    <MKTypography variant="body1" color="white" opacity={0.8} mt={2} mb={1}>
                        Karate:
                    </MKTypography>
                    <>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-user" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                Piet Teun van de Graaf
                            </MKTypography>
                        </MKBox>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-phone" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                (+31) 06 - 38 40 99 25
                            </MKTypography>
                            </MKBox>
                            <MKBox display="flex" color="white" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-envelope" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                mb={2}
                                fontWeight="regular"
                            >
                                karate@sportschooltromp.nl
                            </MKTypography>
                        </MKBox>
                    </>
                    
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={2} mb={1}>
                        Molenlanden / Karate:
                    </MKTypography>
                    <>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-user" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                Walter Rhebergen
                            </MKTypography>
                        </MKBox>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-phone" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                (+31) 06 - 30 31 49 91
                            </MKTypography>
                            </MKBox>
                            <MKBox display="flex" color="white" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-envelope" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                molenlanden@sportschooltromp.nl
                            </MKTypography>
                        </MKBox>
                        <MKBox m={0.5}>
                        <MKButton variant="text" color="white" size="large" iconOnly href="https://www.facebook.com/SportschoolTrompMolenlanden">
                            <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        </MKBox>
                    </>

                    <MKTypography variant="body1" color="white" opacity={0.8} mt={2} mb={1}>
                        Ikigai:
                    </MKTypography>
                    <>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-user" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                Ilse van der Hoeven
                            </MKTypography>
                        </MKBox>
                        <MKBox display="flex" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-phone" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                (+31) 06 - 38 22 18 53
                            </MKTypography>
                            </MKBox>
                            <MKBox display="flex" color="white" p={0.5}>
                            <MKTypography variant="button" color="white">
                                <i className="fas fa-envelope" />
                            </MKTypography>
                            <MKTypography
                                component="span"
                                variant="button"
                                color="white"
                                opacity={0.8}
                                ml={2}
                                fontWeight="regular"
                            >
                                info@daito-ryu.nl
                            </MKTypography>
                        </MKBox>
                        <MKBox m={0.5}>
                        <MKButton variant="text" color="white" size="large" iconOnly href="https://www.facebook.com/Budo-Ryu-Ikigai-109370314193494">
                            <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        <MKButton variant="text" color="white" size="large" iconOnly href="https://www.daito-ryu.nl/index.html">
                            <i className="fab fa-dribbble" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        </MKBox>
                    </>

                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <ContactForm />
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Aanmelden;





function ContactForm() {
    const validPhone = new RegExp('^([+]?)+([0-9]{10,})');
    const validEmail = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$');
  
  
    const [sport, setSport] = useState("0");
    const [naam, setNaam] = useState("");
    const [nummer, setNummer] = useState("");
    const [email, setEmail] = useState("");
    const [bericht, setBericht] = useState("");
    const [sportError, setSportError] = useState(null);
    const [naamError, setNaamError] = useState(null);
    const [nummerError, setNummerError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [berichtError, setBerichtError] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [initial, setInitial] = useState(true);
    const [mailSend, setMailSend] = useState(undefined);

    const captchaRef = useRef(null)
  
  
    const checkData = () => {
      (sport == "0") ? setSportError("Verplicht") : setSportError("");
      (naam == "") ? setNaamError("Verplicht") : setNaamError("");
      if (email == "")
          setEmailError("Verplicht");
      else
          (validEmail.test(email)) ? setEmailError("") : setEmailError("Geen geldig email adres");
  
      (bericht == "") ? setBerichtError("Verplicht") : setBerichtError("");
  
      if (nummer == "")
          setNummerError(null)
      else
          (validPhone.test(nummer)) ? setNummerError("") : setNummerError("geen geldig nummer: Minimaal 10 cijfers en alleen cijfers en een + toegestaan")
    }
  
    useMemo(async () =>
    {
        console.log("Submit");
      if (initial)
      {
          setInitial(false)
          return;
      }
      const token = captchaRef.current.getValue();
      //console.log(token);

        // console.log("Value:" + sport + " || " + naam + " || " + nummer + " || " + email + " || " + bericht);
        // console.log("Check:" + sportError + " || " + naamError + " || " + nummerError + " || " + emailError + " || " + berichtError);
        checkData();
        setMailSend();
      if (sportError == "" && naamError == "" && (nummerError == "" || nummerError == null) && emailError == "" && berichtError == "" && token != "")
      {
        if (mailSend == undefined && submit == false)
        {
            console.log("Try send mail");
            setSport(0);

            const data = {
                name: naam,
                number: nummer ?? "",
                email: email,
                sport: sport,
                msg: bericht
              };
          
          
              await axios.get('https://api.budocentrum-sliedrecht.nl/api/csrf-cookie');
          
                await axios.post(`https://api.budocentrum-sliedrecht.nl/api/sportschooltromp/sendInfo`, data )
                .then(resp => {
                    setMailSend(<Alert severity="success">Bericht is verzonden en er is een bevestiging naar uw email gestuurd.</Alert>);
                    
                    setSport(0);
                    setNaam("");
                    setNummer("");
                    setEmail("");
                    setBericht("");
                    captchaRef.current.reset();
                })
                .catch(error => {
                    setMailSend(<Alert severity="error">Sorry er is iets fout gegaan met versturen.</Alert>);
                })
            captchaRef.current.reset();
        }
      }
      else if (submit == false)
      {
        setMailSend(<Alert severity="error">Er zijn fouten in het formulier gevonden.</Alert>);
        captchaRef.current.reset();
      }

      setSubmit(false);
    }, [submit]);

    return (
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Neem contact op met een van de scholen
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                        Wilt u graag contact met een van de scholen opnemen. Selecteer die school in de lijst hieronder en vul het formulier in. Na verzenden er zal zo snel mogelijjk gereageert proberen te worden.
                    </MKTypography>
                  </MKBox>

                    { mailSend }
                  
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                      <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Ik zou graag de volgende sport proberen:
                            </InputLabel>
                            <NativeSelect
                            defaultValue={sport}
                            inputProps={{
                                name: 'Sport',
                                id: 'uncontrolled-native',
                            }}
                            onChange={(event) => {setSport(event.target.options.selectedIndex)}}
                            error={(sportError != null && sportError !="") ? true : undefined }
                            success={( sportError != "") ?  true : undefined }
                            >
                            <option value={0}>Selecteer</option>
                            <option value={1}>Judo</option>
                            <option value={2}>Karate</option>
                            <option value={3}>Aikibudo</option>
                            <option value={4}>Grappling / BJJ</option>
                            <option value={5}>Daito-ryu Aiki JuJutsu</option>
                            <option value={6}>Molenlanden</option>
                            </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Uw naam is"
                          placeholder="Volledige naam"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          value={naam}
                          onChange={(event) => {setNaam(event.target.value)}}
                          error={(naamError != null && naamError !="") ? true : undefined }
                          helperText={(naamError != null && naamError !="") ? naamError : "" }
                          success={( naamError == "") ?  true : undefined }
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Uw telefoonnummer is"
                          placeholder="nummer"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={nummer}
                          onChange={(event) => {setNummer(event.target.value)}}
                          error={(nummerError != null && nummerError !="") ? true : undefined }
                          helperText={(nummerError != null && nummerError != "") ? nummerError : "" }
                          success={( nummerError =="") ?  true : undefined }
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Uw E-mail is"
                          placeholder="E-mail"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          value={email}
                          onChange={(event) => {setEmail(event.target.value)}}
                          error={(emailError != null && emailError !="") ? true : undefined }
                          helperText={(emailError != null && emailError != "") ? emailError : "" }
                          success={( emailError =="") ?  true : undefined }
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Exta opmerking"
                          placeholder="Vul hier eventuele opmerkingen of extra infomatie in"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          value={bericht}
                          onChange={(event) => {setBericht(event.target.value)}}
                          error={(berichtError != null && berichtError !="") ? true : undefined }
                          helperText={(berichtError != null && berichtError != "") ? berichtError : "" }
                          success={( berichtError =="") ?  true : undefined }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <ReCAPTCHA sitekey="6LfV1bggAAAAAJKCNbDttlKZn98ps226RboSZMOP" ref={captchaRef} />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant="gradient" color="info" onClick={() => setSubmit(true)}>
                        Stuur bericht
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
    );
}