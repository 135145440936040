/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Tijden() {
  return (
    <MKBox
      component="section"
      sx={{
        background: 'linear-gradient(to bottom,  #0092d2 0%,#0092d2 45%,#0c5ca3 100%)',
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      color="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <MKTypography
        variant="h1"
        color="white"
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["3xl"],
          },
        })}
      >
        Lestijden
      </MKTypography>
      <Container>
        <Grid container spacing={3} alignItems="">

            <Grid item xs={12} xl={6}>
              <Grid item xs={12} md={6}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Maandag:
                </MKTypography>
                
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MKTypography
                      variant="h4"
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                      })}
                    >
                      Sliedrecht 
                    </MKTypography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>19:00 - 20:30</i></Grid>
                    <Grid item xs={6}>Grappling / BJJ<br /><i>(NO-GI)</i></Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>20:30 - 22:00</i></Grid>
                    <Grid item xs={6}>Aikibudo<br /><i>(Vrij trainen)</i></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} xl={6}>
              <Grid item xs={12}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Dinsdag:
                </MKTypography>
                
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                      <MKTypography
                        variant="h4"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                      >
                        Nieuw-Lekkerland (Klipper)
                      </MKTypography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>17:00 - 18:00</i></Grid>
                      <Grid item xs={6}>Jeugd Karate<br /><i>(8-10 Jaar)</i></Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MKTypography
                        variant="h4"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                      >
                        Nieuw-Lekkerland (Carillon)
                      </MKTypography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>18:30 - 19:30</i></Grid>
                      <Grid item xs={6}>Jeugd Karate<br /><i>(11-15 Jaar)</i></Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>19:30 - 20:30</i></Grid>
                      <Grid item xs={6}>Karate<br /><i>(vanaf 16 jaar)</i></Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                      <MKTypography
                        variant="h4"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                      >
                        Sliedrecht
                      </MKTypography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>20:30 - 21:45</i></Grid>
                      <Grid item xs={6}>Daito-ryu Aiki JuJutsu</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

           
            <Grid item xs={12} xl={6}>
              <Grid item xs={12}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Woensdag:
                </MKTypography>
                
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                      <MKTypography
                        variant="h4"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                      >
                        Sliedrecht
                      </MKTypography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>19:00 - 20:30</i></Grid>
                      <Grid item xs={6}>BJJ</Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>20:30 - 22:00</i></Grid>
                      <Grid item xs={6}>Aikibudo</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} xl={6}>
              <Grid item xs={12}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Donderdag:
                </MKTypography>
                
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    
                    <Grid item xs={12}>
                      <MKTypography
                        variant="h4"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                      >
                        Sliedrecht 
                      </MKTypography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>16:00 - 17:00</i></Grid>
                      <Grid item xs={6}>Jeugd Judo<br /><i>(5-9 Jaar)</i></Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>17:00 - 18:00</i></Grid>
                      <Grid item xs={6}>Jeugd Judo<br /><i>(vanaf 10 Jaar)</i></Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    
                    <Grid item xs={12}>
                      <MKTypography
                        variant="h4"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                      >
                        Streefkerk
                      </MKTypography>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}><i>19:30 - 20:30</i></Grid>
                      <Grid item xs={6}>Karate<br /><i>(Alle leeftijden)</i></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} xl={6}>
              <Grid item xs={12} md={6}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Vrijdag:
                </MKTypography>
                
                <Grid container spacing={1}>                    
                  <Grid item xs={12}>
                    <MKTypography
                      variant="h4"
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                      })}
                    >
                      Sliedrecht 
                    </MKTypography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>18:30 - 19:30</i></Grid>
                    <Grid item xs={6}>Karate</Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>19:30 - 21:00</i></Grid>
                    <Grid item xs={6}>BJJ</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            
            <Grid item xs={12} xl={6}>
              <Grid item xs={12} md={6}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Zaterdag:
                </MKTypography>
                
                <Grid container spacing={1}>                    
                  <Grid item xs={12}>
                    <MKTypography
                      variant="h4"
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                      })}
                    >
                      Sliedrecht 
                    </MKTypography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>11:00 - 12:30</i></Grid>
                    <Grid item xs={6}>Karate</Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>13:30 - 14:30</i></Grid>
                    <Grid item xs={6}>Jeugd Judo<br /><i>(5 - 9 Jaar)</i></Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>14:30 - 15:30</i></Grid>
                    <Grid item xs={6}>Jeugd Judo<br /><i>(vanaf 10 Jaar)</i></Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>15:45 - 17:00</i></Grid>
                    <Grid item xs={6}>Aikibudo</Grid>
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} xl={6}>
              <Grid item xs={12} md={6}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Zondag:
                </MKTypography>
                
                <Grid container spacing={1}>                    
                  <Grid item xs={12}>
                    <MKTypography
                      variant="h4"
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                      })}
                    >
                      Sliedrecht 
                    </MKTypography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}><i>9:30 - 11:30</i></Grid>
                    <Grid item xs={6}>Vrij trainen</Grid>
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>


        </Grid>
      </Container>
    </MKBox>
  );
}

export default Tijden;
