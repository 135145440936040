/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Vakanties() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{
        display: "grid",
        placeItems: "center",
      }}
    >
      <MKTypography
        variant="h1"
        color="white"
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["3xl"],
          },
        })}
      >
        Vakanties & Feestdagen 2024-2025
      </MKTypography>
      <Container>
        <Grid container spacing={3} alignItems="">

            <Grid item xs={12} lg={6}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Herfstvakantie:
                </MKTypography>
                <MKTypography
                  variant="h5"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  27 Oktober 2024 t/m 3 November 2024
                </MKTypography>

                <MKTypography
                  variant="h2"
                  color="white"
                  mt="1em"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Kerstvakantie:
                </MKTypography>
                <MKTypography
                  variant="h5"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  22 December 2024 t/m 5 Januari 2025
                </MKTypography>

                <MKTypography
                  mt="1em"
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Voorjaarsvakantie:
                </MKTypography>
                <MKTypography
                  variant="h5"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  23 Februari 2025 t/m 2 Maart 2025
                </MKTypography>
                
                <MKTypography
                  mt="1em"
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Meivakantie
                </MKTypography>
                <MKTypography
                  variant="h5"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  25 April 2025 t/m 4 Mei 2024
                </MKTypography>
                
                <MKTypography
                  mt="1em"
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Zomervakantie:
                </MKTypography>
                <MKTypography
                  variant="h5"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  19 Juli 2025 t/m 31 Augustus 2025
                </MKTypography>
            </Grid>

            <Grid item xs={12} lg={6}>
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Hemelvaartsdag:
                </MKTypography>
                <MKTypography
                  variant="h5"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Donderdag 29 mei 2025
                </MKTypography>
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Vakanties;
