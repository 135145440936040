/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import judo from "assets/images/Judo_Small.jpg";
import karate from "assets/images/Karate_Small.jpg";
import aikibudo from "assets/images/Aikibudo_Small.jpg";
import bjj from "assets/images/BJJ_Small.jpg";
import daito from "assets/images/Daito_Small.jpg";

function Sporten() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Welke stijlen geven wij les in?
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={2}>
              <HorizontalTeamCard
                image={judo}
                name="Judo"
                path="/judo"
                position={{ color: "info", label: "Sliedrecht" }}
                description="Judo is een verdedigingssport en een zelfbeheersingssport. Je leert er op de goede manier vallen en je te concentreren. Je leert oefeningen zoals valoefeningen, voorwaarts rollen, maar ook worpen, houdgrepen, wurggrepen en klemmen."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={karate}
                name="Karate"
                path="/karate"
                position={{ color: "info", label: "Sliedrecht, Nieuw-Lekkerland & Streefkerk" }}
                description="Karateka zijn gespecialiseerd in snelle vuiststoten en beentechnieken. Je leert de sterke punten van je lichaam te gebruiken als wapen. Tevens leer je de zwakke lichaamspunten kennen van je tegenstander en op welke wijze deze kunnen worden aangevallen."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={aikibudo}
                name="Aikibudo"
                path="/aikibudo"
                position={{ color: "info", label: "Sliedrecht" }}
                description="Aikibudo of aikido is een perfecte vorm van zelfverdediging maar ook een mentale training. De technieken stammen voor een groot deel uit de beroemde Japanse Daito school. Oorspronkelijk werd deze krijgskunst Daito ryu jitsu en later Aiki jitsu genoemd. Aikibudo is geen wedstrijdsport, maar een verdedigingskunst die opvalt door zijn cirkelvormige bewegingen."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={bjj}
                name="Braziliaans Jiu-Jitsu/Grappling"
                path="/bjj"
                position={{ color: "info", label: "Sliedrecht (Valt onder Renzo Gracie Holland)" }}
                description="Met BJJ werk je aan je fysieke en mentale weerbaarheid en aan je conditie. Braziliaans Jiu-Jitsu specialiseert zich in het grondgevecht en kent zoveel senario's dat je deze sport jaren lang met plezier blijft doen. De lessen worden gegeven met pak(GI) of in fightshort/rashguard No-Gi(grappling)."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={daito}
                name="Daito-ryu Aiki JuJutsu"
                path="/daito"
                position={{ color: "info", label: "Sliedrecht" }}
                description="Daito-ryu Aikijujutsu is een traditionele Japans vorm van Budo zoals vele honderden jaren door de Takeda familie is beoefend. De technieken vinden hun oorsprong in de klasse van de Samourai en is ontwikkeld als een methode van zelfverdediging tegen aanvallen waarbij de nadruk ligt in het neutraliseren van het geweld en niet het zelf veroorzaken."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Sporten;
