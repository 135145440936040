/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

import foto from "assets/images/wado-ryu-gishin-funakoshi.jpg"

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={5}>
            <Grid item xs={12}>
              <p>
                Karate staat vooral bekend om stoten en trappen. Karateka zijn doorgaans dan ook gespecialiseerd 
                in snelle vuiststoten en beentechnieken. In onze sportschool wordt het Wado Ryu karate-do 
                beoefend. Het Wado Ryu karate-do onderscheidt zich van andere karate stijlen doordat het naast de 
                gebruikelijke karate-do technieken ook veel worpen, grepen en klemmen kent. Een van de meest 
                kenmerkende dingen in het Wado karate is de nadruk op het gebruik van lichaamsdraaiingen (Tai 
                sabaki). Een aanval wordt vaak 'zacht' en meegaand geneutraliseerd door gebruik te maken van de 
                energie van de tegenstander.  
              </p>
              <p>
                Wij zijn nog steeds bijzonder trots dat in 1976 de stichter van deze stijl Prof. Hironori Ohtsuka onze 
                school bezocht en een indrukwekkende demonstratie verzorgde. Hironori Ohtsuka was een 
                grootmeester in Jujutsu (Shinto Yoshin Ryu Jujutsu kempo). Hij ontwikkelde het Wado Ryu karate-do 
                door het Japanse Jujutsu te combineren met de beste elementen van Okinawaans Karate-do. 
              </p><br />
              <p>
                De karatelessen voor volwassenen zijn onder leiding van Piet Teun van de Graaf (Sliedrecht) en 
                Walter Rhebergen(Nieuw-Lekkerland &amp; Streefkerk). Jeugdlessen zijn onder leiding van Walter Rhebergen(Nieuw-Lekkerland &amp; Streefkerk). In de lessen wordt aandacht gegeven aan de 
                bredere krijgskunst, waarbij karate technieken soms worden gecombineerd met de technieken uit 
                bijvoorbeeld het Aikibudo. In de karatelessen voor de jeugd leren de kinderen op een leuke manier 
                stoot en traptechnieken, valbreken, rollen en zelfverdedigingstechnieken. Op langere termijn zullen 
                de kinderen meer controle krijgen over de technieken. Hierbij zullen ze meer respect voor elkaar 
                krijgen en grotere zelfbeheersing ontwikkelen en worden ze zelfverzekerder. 
              </p>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKTypography
                  variant="h2"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
              >
                Harmonie in sporten en sporten in harmonie
              </MKTypography>
                <p>
                  Karate kan als wedstrijdsport aantrekkelijk zijn en daarbinnen zijn verschillende mogelijkheden en 
                  disciplines (kata en kumite). Binnen onze sportschool wordt karate vooral beoefend als krijgskunst. 
                  Dit betekend focus op techniek en de toepassingen daarbij. Hierbij staat de persoonlijke 
                  ontwikkeling centraal. Het behalen van een zwarte band is dan een ultieme uitdaging. Om bij 
                  complexe bewegingen tot een goed resultaat te komen moeten lichaam en geest in evenwicht 
                  samenwerken. Je leert de sterke punten van je lichaam te gebruiken als wapen. Tevens leer je de 
                  zwakke lichaamspunten van de tegenstander kennen. De diversiteit van de bewegingen en de 
                  gebruikte oefenvormen zorgen voor een uitstekende mentale en lichamelijke conditie. 
                </p><br />
                <p>
                  Binnen het traditionele Wado Ryu karate staat harmonie tussen lichaam en geest voorop. Door 
                  oude krijgskunstoefeningen te gebruiken in combinatie met zelfverdediging, een stukje filosofie en 
                  lichaamskunst, wordt gestreefd deze harmonie te bereiken. Hierbij staan niet alleen bepaalde 
                  fysieke einddoelen, maar juist ook het leerproces centraal. Dit leerproces (de weg naar) is een 
                  persoonlijke ervaring, waarbij (onder andere) het ontwikkelen van intuïtie, concentratie, 
                  weerbaarheid, zelfdiscipline en respect belangrijke onderdelen zijn. Fysieke aanleg is van 
                  ondergeschikt belang. Daarom is traditioneel Wado Ryu karate-do geschikt voor iedereen. 
                </p>
              </Grid>
              <Grid item lg={6}>
                <img src={foto} width="100%" />
              </Grid>
            </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
