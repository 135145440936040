/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

import judo2 from "assets/images/Judo2.jpg"

function Introduction() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              Ben je op zoek naar een Braziliaans Jiu-Jitsu School in Sliedrecht en wil je op een sportieve manier jezelf willen verdedigen? Met BJJ werk je aan je fysieke en mentale weerbaarheid en aan je conditie. Braziliaans Jiu-Jitsu specialiseert zich in het grondgevecht en kent zoveel senario's dat je deze sport jaren lang met plezier blijft doen. De lessen worden gegeven met pak(GI) of in fightshort/rashguard No-Gi(grappling). De sportscholen van Renzo Gracie kenmerken zich door een goede sfeer en onderling respect waardoor het motiverend is om te blijven sporten.
              Onderzoek heeft uitgewezen dat 90 procentvan alle geweldsdelicten op de grond eindigt. Omdat je iemand op de grond makkelijker kan controleren dan in een staande positie, zijn er veel technieken in braziliaans Jiu-Jitsu gericht op het neerhalen van een tegenstander om vervolgens te controleren op de grond in een dominante positie. Niet alleen als verdediging maar ook als wedstrijdsport is het Braziliaans Jiu-Jitsu erg populair. Er worden zowel nationaal als internationaal erg veel toernooien georganiseerd. Maar ook in een sport als het MMA zie je de grote invloed van het BJJ.
              Studenten van deze sport ontwikkelen een brede en diepe kennis van de werking en limieten van het menselijk lichaam. Met deze kennis kun je een tegenstander controleren en onderwerpen op jouw voorwaarden.
              Studenten profiteren van een toegenomen fysieke conditie, een toegenomen probleem-oplossend vermogen, zelfkennis over lichaam en geest en de vele sociale voordelen van het werken in een grote groep van medestudenten die hetzelfde voorstaan en daardoor van elkaar leren en samen plezier hebben. Trainen bij Renzo Gracie Holland vindt plaats in een veilige en sociale omgeving zodat iedereen zijn of haar doelen kan nastreven.
              BJJ/Grappling is uitermate geschikt voor ieder die op een verantwoordelijke manier, intensief en gezond wil sporten.
              De lessen worden gegeven in het BudoCentrum in Sliedrecht.
              De lessen zijn uiteraard voor Dames en Heren.
              De lessen zijn vanaf de leeftijd van 14 jaar
            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Introduction;
